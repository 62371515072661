import React from 'react';
import x from '../../assets/x.png';
import discord from '../../assets/discord.png';
import github from '../../assets/github.png';
import telegram from '../../assets/telegram.png';
import newLogo from '../../assets/new logo.png';
import './footer.scss';

const Footer = () => {
  return (
    <div className="container">
      <div className="footerContainer">
        <div className="left">
          <img src={newLogo} alt="logo" />

          <div className="contractAdress-container">
            <div className="title">Contract Address:</div>
            <p className="address">
              0x5dcd6272c3cbb250823f0b7e6c618bce11b21f90
            </p>
          </div>
          <div className="social-media">
            <a href="https://twitter.com/Pearswapnet" target="_blank">
              <img src={x} alt="" />
            </a>
            <a href="#" target="_blank">
              <img src={discord} alt="" />
            </a>
            <a href="#" target="_blank">
              <img src={github} alt="" />
            </a>
            <a href="https://t.me/PearSwapPortal" target="_blank">
              <img src={telegram} alt="" />
            </a>
          </div>
          <div className="message">
            <p>
              Make sure to copy the attached CA of $PEAR and paste it into
              UniSwap. Avoid copying the CA from other resources. Don't enter
              manually.
            </p>
          </div>
          <div className="copyRight">
            <p>© 2023 PEAR. All rights reserved.</p>
          </div>
        </div>
        <div className="links-container">
          <p className="title">Links</p>
          <div className="links">
            <div className="left-column">
              <a
                className="link"
                href="https://coinmarketcap.com/currencies/pear-swap/"
                target="_blank"
              >
                CoinMarketCap
              </a>
              <a
                className="link"
                href="https://www.coingecko.com/en/coins/pear-swap"
                target="_blank"
              >
                Coingecko
              </a>
              <a
                className="link"
                href="https://www.dextools.io/app/en/ether/pair-explorer/0x7b8ac38aacd8ebca19b3d423bcbbd4c5815bab49"
                target="_blank"
              >
                DexTools
              </a>
              <a
                className="link"
                href="https://etherscan.io/token/0x5dcd6272c3cbb250823f0b7e6c618bce11b21f90"
                target="_blank"
              >
                Etherscan
              </a>
              <a
                className="link"
                href="https://pear-whitepaper.gitbook.io/pear-white-paper/"
                target="_blank"
              >
                WhitePaper
              </a>
            </div>
            <div className="right-column">
              <a
                className="link"
                href="https://www.team.finance/view-coin/0x5dcd6272c3cbb250823f0b7e6c618bce11b21f90?name=Pear%20Swap&symbol=PEAR"
                target="_blank"
              >
                Locked Liquidity
              </a>
              <a
                className="link"
                href="mailto:support@pearswap.net"
                target="_blank"
              >
                Support
              </a>
              <a
                className="link"
                href="https://pear-whitepaper.gitbook.io/pear-white-paper/tokenomics"
                target="_blank"
              >
                Tokenomics
              </a>
              <a
                className="link"
                href="https://app.uniswap.org/#/swap?outputCurrency=0x5dCD6272C3cbb250823F0b7e6C618bce11B21f90&chain=mainnet"
                target="_blank"
              >
                Uniswap
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
