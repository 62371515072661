// @ts-nocheck
import { useState, useEffect } from 'react';
import GreenButton from '../GreenButton';
import './header.scss';
import arrowUpBlack from '../../assets/arrowUp-black.png';

// Images
import logo from '../../assets/logo.png';
import newLogo from '../../assets/new logo.png';
import ethereum from '../../assets/ethereum.png';
import gas from '../../assets/gas.png';
import pear from '../../assets/pear.png';
import {
  getEthPriceInUsd,
  getPearPriceInUsd,
  getGasPrice,
} from '../../services/prices';
const Header = () => {
  const [ethToUsd, setEthToUsd] = useState();
  const [gasPrice, setGasPrice] = useState();
  const [pearToUsd, setPearToUsd] = useState();

  useEffect(() => {
    getEthPriceInUsd().then((data) => setEthToUsd(data));
    getPearPriceInUsd().then((data) => setPearToUsd(data));
    getGasPrice().then((data) => setGasPrice(data));
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1068px)');

    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
    };

    // Initial check
    handleMediaChange(mediaQuery);

    // Add event listener for changes
    mediaQuery.addListener(handleMediaChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      mediaQuery.removeListener(handleMediaChange);
    };
  }, []);

  return (
    <nav className={`head_navigation ${isMobile ? 'mobile' : ''}`}>
      <img src={isMobile ? pear : newLogo} alt="logo" />
      {!isMobile && (
        <div className="logoCenter">
          <HeaderIconText logo={ethereum} title={'$ETH'} text={ethToUsd} />
          <HeaderIconText logo={gas} text={gasPrice} />
          <HeaderIconText logo={pear} title={'$PEAR'} text={pearToUsd} />
        </div>
      )}

      {!isMobile && (
        <div className="actionButton">
          <GreenButton
            text={'Launch dApp'}
            image={arrowUpBlack}
            link="https://app.pearswap.net"
          />
        </div>
      )}

      {isMobile && (
        <GreenButton
          text={'Launch dApp'}
          image={arrowUpBlack}
          link="https://app.pearswap.net"
        />
      )}
    </nav>
  );
};

export default Header;

const HeaderIconText = ({ logo, title, text }) => (
  <div className="headerIconText">
    <img src={logo} alt="logo" />
    <div className="headerIconData">
      {title && <p>{title}</p>}
      <p>${Number(text).toFixed(2)}</p>
    </div>
  </div>
);
