import React from 'react';
import './greenButton.scss';

const GreenButton = ({ text, image, link }) => {
	return (
		<a className='greenButton'  href={link}>
			{text}
			{image && (
				<span style={{ margin: '4px' }}>
					<img
						src={image}
						alt='img'
						width={10}
						height={10}
						style={{ objectFit: 'contain', marginLeft: '5px' }}
					/>
				</span>
			)}
		</a>
	);
};
export default GreenButton;
