import axios from 'axios';
import { formatGwei } from 'viem';

const { Alchemy, Network } = require('alchemy-sdk');
const config = {
  apiKey: 'u5GM-dIPljr6-dXW8zSJdshCCDCINWUw', // Replace with your API key
  network: Network.ETH_MAINNET, // Replace with your network
};
const alchemy = new Alchemy(config);
console.log(process.env.ALCHEMY_API);
export const getEthPriceInUsd = async () => {
  const { data } = await axios.get(
    `https://api.coinbase.com/v2/prices/ETH-USD/spot`,
    {
      cache: {
        interpretHeader: false,
        ttl: Number(60000),
      },
    }
  );

  const {
    data: { amount },
  } = data;

  return amount;
};
export const getGasPrice = async () => {
  let response = await alchemy.core.getGasPrice();
  return formatGwei(response);
};
export const getPearPriceInUsd = async () => {
  const { data } = await axios(
    `https://api.dexscreener.com/latest/dex/pairs/ethereum/0x7b8ac38aacd8ebca19b3d423bcbbd4c5815bab49`,
    {
      cache: {
        interpretHeader: false,
        ttl: Number(60000),
      },
    }
  );

  return data?.pairs?.[0]?.priceUsd;
};
